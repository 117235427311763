/* eslint-disable */
import { Redirect, Route, Switch } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';


import { AppRoute } from '../config/route-config';
import PayfonePrefill from '../components/PayfonePrefill';
import BasicInfo from '../components/BasicInfo';
import EmployerInfo from '../components/EmployerInfo';
import ResidenceInfo from '../components/ResidenceInfo';
import ReferenceInfo from '../components/ReferenceInfo';
import ReviewInfo from '../components/ReviewInfo';
import { ApprovalPage } from '../components/ApprovalPage';
import IdentityVerify from '../components/IdentifyVerify';
import UserInfo from '../components/UserInfo';
import DofStartingPage from '../components/DofStartingPage';

// Customver Verification Optimization Component import starts

import { DOFSubmitted } from '../components/CustomerVerification/VerificationComponents/DOFSubmitted';
import { IdentityTab } from '../components/CustomerVerification/VerificationComponents/IdentityTab';
import { ResidenceTab } from '../components/CustomerVerification/VerificationComponents/ResidenceTab';
import { IncomeTab } from '../components/CustomerVerification/VerificationComponents/Income';
import ReferenceTab from '../components/CustomerVerification/VerificationComponents/ReferenceTab';
import { ReviewInformation } from '../components/CustomerVerification/VerificationComponents/ReviewInformation';
import { WelcomePage } from '../components/CustomerVerification/VerificationComponents/WelcomePage';
import { OTPAuthenticationTab } from '../components/CustomerVerification/VerificationComponents/OTPAuthentication';
import DOFStartingPage from '../components/CustomerVerification/VerificationComponents/DOFStartingPage';
import { getConfigDetails, validateAuthKey, generateDOFAccessLink, GetApproval } from '../api/user';
import { DigitalOrderFormContext } from '../components/CustomerVerification/Context/DigitialOrderFormContext';

import ScreenLoader from '../components/CustomerVerification/Common/ScreenLoader';
import { checkRACPadCustomer } from '../components/CustomerVerification/Common/HelperFunctions';

export const routesTestId = 'routesTestId';


export const Routes = () => {
  const history = useHistory();
  const queryParams = new URLSearchParams(window.location.search);
  const storeCode = queryParams.get("StoreCode");
  const authKey = queryParams.get("AuthKey")

  const { decisionEngineDetails, setDecisionEngineDetails, setCustomerId, setRacPadCustomerDetail } = useContext(DigitalOrderFormContext)



  const [customerVerificationOptimizationEnable, SetcustomerVerificationOptimizationEnable] = useState<boolean>(true)
  const [screenLoader, setScreenLoader] = useState(false)
  const params = new URLSearchParams(window.location.search);
  const EncodeAuthKey = params.get('AuthKey');
  const EncodedStorecode = params.get('StoreCode') || '';
  const EncodeUserId = params.get('userId') || '';
  const EncodeClientId: any = params.get('clientId')
  const EncodedeId = params.get('deId')
  const EncodeSalesLeadId = params.get('salesLeadId')

  useEffect(() => {


    if (EncodedStorecode) {
      window.sessionStorage.setItem("encodeStoreNumber", (EncodedStorecode));
      window.sessionStorage.setItem("storeNumber", atob(EncodedStorecode));
    }
    if (EncodeUserId) {
      window.sessionStorage.setItem("encodeUserId", atob(EncodeUserId));
    }
    if (EncodeAuthKey) {
      window.sessionStorage.setItem("authKey", EncodeAuthKey);
    }
    if (EncodeClientId) {
      window.sessionStorage.setItem("clientId", EncodeClientId);
    }
    if (EncodedeId) {
      window.sessionStorage.setItem("approvalId", atob(EncodedeId));
    }
    if (EncodeSalesLeadId) {
      window.sessionStorage.setItem("salesLeadId", atob(EncodeSalesLeadId));
    }
    validateAuthKeyFn(EncodeAuthKey ? EncodeAuthKey : window.sessionStorage.getItem('authKey'), EncodedStorecode ? EncodedStorecode : window.sessionStorage.getItem('encodeStoreNumber'))

  }, []);

  // Get Config Details Function

  const getConfigDetailsFn = async () => {
    debugger
    const StoreId = window.sessionStorage.getItem('storeNumber')
    const featureFlagReq = {
      storeNumbers: [window.sessionStorage.getItem('storeNumber')],
      paramKeyNames: ['DOFCustomerVerification'],
    };
    let FeatureFlagResponse

    if (window.sessionStorage.getItem('dofFeatureFlag') == '1') {
      await getCustomerDetails()
    }
    else if (window.sessionStorage.getItem('dofFeatureFlag') == '0') {
      setScreenLoader(false)
      SetcustomerVerificationOptimizationEnable(false)
      return
    }
    else if (StoreId && StoreId != null && StoreId != undefined && StoreId != '' && StoreId != 'null' && StoreId != 'undefined') {
      FeatureFlagResponse = await getConfigDetails(
        featureFlagReq, window.sessionStorage.getItem('authKey') ? false : window.sessionStorage.getItem('storeNumber'),
        window.sessionStorage.getItem('authKey') ? window.sessionStorage.getItem('authKey') : window.sessionStorage.getItem('storeNumber')
      );
      if (FeatureFlagResponse?.status == 200) {
        if (FeatureFlagResponse?.data?.storeProfileResponse?.configDetails[0]?.configDetails[0]?.paramValue == '1') {
          setScreenLoader(false)
          window.sessionStorage.setItem("dofFeatureFlag", '1');
          await getCustomerDetails()
        }
        else {
          setScreenLoader(false)
          SetcustomerVerificationOptimizationEnable(false)
        }
      }
      else {
        setScreenLoader(false)
        SetcustomerVerificationOptimizationEnable(false)
      }
    }

  };

  // Function for Validating the authKey
  const validateAuthKeyFn = async (EncodeAuthKey?: any, EncodedStorecode?: any) => {
    setScreenLoader(true)
    if (EncodeAuthKey) {
      console.log('flow of auth');
      let ResponseValidateAuth: any = await validateAuthKey(EncodeAuthKey, false);
      ResponseValidateAuth = ResponseValidateAuth?.data
      console.log(ResponseValidateAuth, 'response');
      if (
        ResponseValidateAuth &&
        ResponseValidateAuth?.valid === true
      ) {
        getConfigDetailsFn()
      }
      else {
        history.push(`/otp/auth/${window.sessionStorage.getItem('approvalId')}`)
      }
    } else if (EncodedStorecode) {
      console.log('storeCode flow');
      const DecodeStoreCode = new Buffer(EncodedStorecode, 'base64');
      let DecodedNum = DecodeStoreCode.toString('ascii');
      const ValidateStore = await validateAuthKey(DecodedNum, true);
      console.log(ValidateStore, 'validates');
      if (ValidateStore) {
        getConfigDetailsFn()
      }
      else {
        history.push(`/otp/auth/${window.sessionStorage.getItem('approvalId')}`)
      }
    }
  };


  //  Function for getting the customer Details 

  const getCustomerDetails = async () => {
    debugger
    setScreenLoader(true)
    const request: any = {
      "decisionId": window.sessionStorage.getItem('approvalId')
    }
    // Calling the Get Approval Service
    if (request?.decisionId) {
      const getApprovalServiceResponse: any = await GetApproval(request, window.sessionStorage.getItem('authKey') ? false : window.sessionStorage.getItem('storeNumber'),
        window.sessionStorage.getItem('authKey') ? window.sessionStorage.getItem('authKey') : window.sessionStorage.getItem('storeNumber'));

      console.log('Get Approval Service Response', getApprovalServiceResponse);
      if (getApprovalServiceResponse && getApprovalServiceResponse?.globalCustomerId) {
        console.log('getApprovalServiceResponse.globalCustomerId', getApprovalServiceResponse?.globalCustomerId);
        setDecisionEngineDetails(getApprovalServiceResponse)
        setScreenLoader(false);
        checkRACPadCustomer(getApprovalServiceResponse?.globalCustomerId, setRacPadCustomerDetail, setCustomerId);

        console.log('Get Approval Service Response', getApprovalServiceResponse);
        if (getApprovalServiceResponse && getApprovalServiceResponse?.globalCustomerId) {
          console.log('getApprovalServiceResponse.globalCustomerId', getApprovalServiceResponse?.globalCustomerId);
          setDecisionEngineDetails(getApprovalServiceResponse)
          setScreenLoader(false);
          checkRACPadCustomer(getApprovalServiceResponse?.globalCustomerId, setRacPadCustomerDetail, setCustomerId);
          const clientId: any = window.sessionStorage.getItem('clientId')
          if (atob(clientId) == 'EC' && (storeCode || authKey)) {
            history.push(`/dof/verification/${window.sessionStorage.getItem('approvalId')}`)
          }
          else if (storeCode || authKey) {
            history.push(`/welcome/${window.sessionStorage.getItem('approvalId')}`)
          }
        }
        else {
          setScreenLoader(false)
          history.push(`/welcome/${window.sessionStorage.getItem('approvalId')}`)
        }
      }
      else {
        setScreenLoader(false)
        history.push(`/welcome/${window.sessionStorage.getItem('approvalId')}`)
      }

    };
  }

  return (

    < div data-testid={routesTestId}>

      {screenLoader ? <ScreenLoader /> : null}

      {customerVerificationOptimizationEnable ? <>
        <Switch>

          <Route
            exact
            path={AppRoute.dofStartPage}
            key="/dof/verification/:approvalId"
            component={DOFStartingPage}
          />
          <Route
            path={AppRoute.welcomepage}
            key="/welcome/:approvalId"
            component={WelcomePage}
          />
          <Route
            path={AppRoute.indentitytab}
            key="/identity/:customerId/:approvalId"
            component={IdentityTab}
          />
          <Route
            path={AppRoute.residenceTab}
            key="/residence/:customerId/:approvalId"
            component={ResidenceTab}
          />
          <Route
            path={AppRoute.incomeTab}
            key="/income/:customerId/:approvalId"
            component={IncomeTab}
          />
          <Route
            path={AppRoute.reference}
            key="/reference/:customerId/:approvalId"
            component={ReferenceTab}
          />
          <Route
            path={AppRoute.reviewInformation}
            key="/reviewInformation/:customerId?/:approvalId?"
            component={ReviewInformation}
          />
          <Route
            path={AppRoute.dofSubmitted}
            key="/dof/submission/:customerId/:approvalId"
            component={DOFSubmitted}
          />
          <Route
            path={AppRoute.dofAuthentication}
            key="/otp/auth/:approvalId"
            component={OTPAuthenticationTab}
          />
        </Switch>


      </> :
        <Switch>
          <Route
            exact
            path={AppRoute.startingPage}
            key="/"
            component={DofStartingPage}
          />
          <Route
            exact
            path={AppRoute.payfoneprefill}
            key="/payfoneprefill"
            component={PayfonePrefill}
          />
          <Route
            exact
            path={AppRoute.basicInfo}
            key="/basicinfo"
            component={BasicInfo}
          />
          <Route
            exact
            path={AppRoute.employerInfo}
            key="/employerinfo"
            component={EmployerInfo}
          />
          <Route
            exact
            path={AppRoute.residenceInfo}
            key="/residenceinfo"
            component={ResidenceInfo}
          />
          <Route
            exact
            path={AppRoute.referenceInfo}
            key="/referenceinfo"
            component={ReferenceInfo}
          />
          <Route
            exact
            path={AppRoute.reviewInfo}
            key="/reviewInfo"
            component={ReviewInfo}
          />
          <Route
            exact
            path={AppRoute.ApprovalPage}
            key="/ApprovalPage"
            component={ApprovalPage}
          />
          <Route
            exact
            path={AppRoute.identifyVerify}
            key="/IdentityVerify"
            component={IdentityVerify}
          />
          <Route
            exact
            path={AppRoute.userInfo}
            key="/userInfo"
            component={UserInfo}
          />
        </Switch>
      }
    </div >
  )


}
