/* eslint-disable prettier/prettier */
import React, { createContext, useState } from 'react';
import { dropdown } from '../Common/HelperFunctions';
export const ReferenceTabContext = createContext<any>('');
export function ReferenceTabContextProvider(props: any) {
    const [relationshipOptions, setRelationshipOptions] = useState<dropdown[]>([]);

    const [familyReferenceDetails, setFamilyReferenceDetails] = useState({
        actualReferenceName: '',
        actualRelationShipType: '',
        actualPhoneNumber: '',
        referenceName: '',
        relationShipType: '',
        phoneNumber: ''
    })
    const [nonFamilyReferenceDetails, setnonFamilyReferenceDetails] = useState<any>({
        actualReferenceName: '',
        actualRelationShipType: '',
        actualPhoneNumber: '',
        referenceName: '',
        relationShipType: '',
        phoneNumber: ''
    })

    return (
        <div>
            <ReferenceTabContext.Provider
                value={{
                    relationshipOptions, setRelationshipOptions,
                    familyReferenceDetails, setFamilyReferenceDetails,
                    nonFamilyReferenceDetails, setnonFamilyReferenceDetails

                }}
            >
                {props.children}
            </ReferenceTabContext.Provider>
        </div>
    );
}
