/* eslint-disable */
import React from "react";
import { ReactComponent as TickIcon } from "../../../images/success-icon.svg";
import { Grid, Typography, RACButton } from '@rentacenter/racstrap';
import doflogo from '../../../images/logo.svg';

type StepperProps = {
  identity: boolean;
  residence: boolean;
  income: boolean;
  reference: boolean;
  activeStep: string; // This prop represents the current active step
};

const DOFHeader: React.FC<StepperProps> = ({ identity, residence, income, reference, activeStep }) => {
  const clientId: any = window.sessionStorage.getItem('clientId')

  const steps = [
    { label: "Identity", isCompleted: identity, isActive: activeStep === "Identity" },
    { label: "Residence", isCompleted: residence, isActive: activeStep === "Residence" },
    { label: "Income", isCompleted: income, isActive: activeStep === "Income" },
    { label: "Reference", isCompleted: reference, isActive: activeStep === "Reference" },
  ];

  return (
    <>
      {atob(clientId) != "EC" ? (
        <Grid style={{ textAlign: "center", display: "inline-flex" }}>
          <img
            src={doflogo}
            style={{
              float: "left",
              verticalAlign: "middle",
              marginTop: "20px",
              marginLeft: "10px",
            }}
          />
        </Grid>
      ) : null}

      <div style={styles.stepperContainer}>
        <div style={styles.stepper}>
          {steps.map((step, index) => {
            const { label, isCompleted, isActive } = step;

            return (
              <React.Fragment key={label}>
                {/* Step Circle */}
                <div style={styles.stepWrapper}>
                  <div
                    style={{
                      ...styles.circle,
                      ...(isCompleted ? styles.completedCircle : {}),
                      ...(isActive && label === activeStep
                        ? styles.activeCircle
                        : {}),
                      ...(label !== activeStep && !isActive
                        ? styles.inactiveCircle
                        : {}),
                    }}
                  >
                    {isCompleted ? <TickIcon /> : index + 1}
                  </div>
                  <p
                    style={{
                      ...styles.label,
                      color:
                        isActive && label === activeStep ? "#007bff" : "#333", // Blue for active step
                    }}
                  >
                    {label}
                  </p>
                </div>

                {/* Connector Line */}
                {index < steps.length - 1 && (
                  <div
                    style={{
                      ...styles.line,
                      backgroundColor:
                        isCompleted || isActive ? "rgb(197 201 205)" : "#ccc", // Blue for completed or active steps
                    }}
                  />
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </>
  );
};

const styles: any = {
  stepperContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0 10px",
    marginTop: "20px",
  },
  stepper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between", // Ensure the circles are spaced out evenly
    width: "100%",
    maxWidth: "800px", // Width for large screens
    position: "relative",
  },
  stepWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    zIndex: 1, // Ensures circles stay on top of the line
  },
  circle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "40px",
    height: "40px",
    borderRadius: "50%",
    fontWeight: "bold",
    fontSize: "14px",
    color: "#333",
    margin: "0 20px", // Add horizontal margin between the circles
    border: "2px solid #ccc", // Default gray border color for inactive steps
    position: "relative",
    zIndex: 1,
  },
  completedCircle: {
    backgroundColor: "#fff",
    color: "#007bff", // Completed circle has blue checkmark
    border: "2px solid #007bff", // Completed circle will have a blue border
  },
  activeCircle: {
    border: "2px solid #007bff", // Highlight the active circle with blue border
    backgroundColor: "#fff", // Active circle should have a white background
    color: "#007bff", // Active number should be blue
  },
  inactiveCircle: {
    border: "2px solid #ccc", // Non-active circles should have a gray border
    color: "#333", // Black text for non-active circles
  },
  label: {
    marginTop: "8px",
    fontSize: "12px",
    textAlign: "center",
  },
  line: {
    position: "absolute",
    top: "50%", // Place the line in the middle of the circles vertically
    left: "calc(50% + 4px)", // Offset the line's left position to the center of the next circle, considering the margin
    width: "calc(100% - 80px)", // Make the line fit between circles with spacing
    height: "2px", // The line height should be small and thin
    backgroundColor: "rgb(197 201 205)", // Default line color
    transform: "translateX(-50%)", // Center the line horizontally between circles
    zIndex: 0, // Ensure the line is behind the circles
  },

  // Media Queries for responsiveness
  "@media (max-width: 768px)": {
    stepper: {
      flexDirection: "column", // Stack vertically on small screens
      alignItems: "center",
    },
    line: {
      width: "2px",
      height: "40px",
      margin: "10px 0",
    },
    stepWrapper: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    label: {
      fontSize: "10px",
      marginTop: "5px",
    },
  },
};

export default DOFHeader;
