/* eslint-disable */

import React, { useContext, useEffect, useState } from 'react';
import {
  Grid,
  RACButton,
  RACCheckBox,
  RACSelect,
  RACTextbox,
  Typography,
} from "@rentacenter/racstrap";

import { DigitalOrderFormContext } from '../Context/DigitialOrderFormContext';
import { VerificationStyles } from '../../../styles/verificationStyles';
import ScreenLoader from '../Common/ScreenLoader';
import { useHistory, useParams } from 'react-router-dom';
import { getCustomerDetails } from '../Common/GetCustomerDetails';
import { CustomerInfo } from '../../../interfaces/CustomerVerificationModel/DigitalOrderFormModel';
import { GetDropOptions, UpdateCustomerInfo } from '../../../api/user';
import { ReferenceTabContext } from '../Context/ReferenceDetailsContext';
import { buildDropdownList, updateCustomerApproval } from '../Common/HelperFunctions';
import DOFHeader from './DOFHeader';
import { nonFamilyReferenceRefcode } from '../../../constants/constants';

interface Reference {
  relationship: string;
  name: string;
  phone: string;
}

const initialReferences: Reference[] = [
  { relationship: '', name: '', phone: '' },
  { relationship: '', name: '', phone: '' },
];


const ReferenceTab = () => {
  const classes: any = VerificationStyles();

  const { customerDetails, setCustomerDetails, setIntellicheckVerfDetails } = useContext(DigitalOrderFormContext);
  const { relationshipOptions, setRelationshipOptions, familyReferenceDetails, setFamilyReferenceDetails,
    nonFamilyReferenceDetails, setnonFamilyReferenceDetails } = useContext(ReferenceTabContext)
  const [loader, setLoader] = useState<boolean>(false);
  const history = useHistory();
  const { customerId, approvalId } = useParams<any>();

  useEffect(() => {
    fetchCustomerData(customerId);
  }, []);


  // Main function to fetch customer details 
  const fetchCustomerData = async (customerId: string) => {
    try {
      if (!customerDetails?.personalInfo?.customerId) {
        const customerData: CustomerInfo = await getCustomerDetails(customerId, setCustomerDetails);
        console.log("customerDataReponse", customerData)

        setCustomerDetails(customerData);
      }

      const dropOptionsPayload = {
        references: [
          'CALL_TIME',
          'GOVERNMENT_ID_TYPE',
          'RELATIONSHIP_TYPE',
          'EMPLOYER_PAYSCHEDULE',
          'DE_EXCEPTION_REASON',
        ],
        status: 'Y',
      };
      setLoader(true)
      const getDropOptionsRes: any = await GetDropOptions(dropOptionsPayload, true, '02202')
      setLoader(false);

      console.log("getDropOptionsResponse", getDropOptionsRes)
      if (getDropOptionsRes) {
        setRelationshipOptions(
          buildDropdownList(getDropOptionsRes?.relationshipType)
        );
      }

    } catch (error: any) {
      console.log('Error in fetchCustomerData service', error);
      setLoader(false);
    }
  };


  const handleInputChange = (referenceType?: string, field?: any, value?: any) => {

    if (referenceType == 'family') {

      setFamilyReferenceDetails({ ...familyReferenceDetails, [field]: value.trim() })
    }
    else if (referenceType == 'nonfamily') {
      setnonFamilyReferenceDetails({ ...nonFamilyReferenceDetails, [field]: value.trim() })

    }
  };


  // Reference sav button click

  const ReferenceSaveClick = async () => {
    history.push(`/review/details/${customerId}/${approvalId}`);
    updateCustomerApproval("REFERENCE", approvalId, customerId)
    const payload: any = {
      "customerId": customerId,
      "personalReferences": [
        {
          "personalReferenceId": "",
          "firstName": familyReferenceDetails.referenceName,
          "phoneNumber": familyReferenceDetails.phoneNumber,
          "lastName": "testtt",
          "relationshipType": familyReferenceDetails.relationShipType,
          "doNotCall": "Y"
        },
        ...(nonFamilyReferenceDetails?.referenceName ? [{
          "personalReferenceId": "",
          "firstName": nonFamilyReferenceDetails.referenceName,
          "phoneNumber": nonFamilyReferenceDetails.phoneNumber,
          "lastName": "mohan",
          "relationshipType": nonFamilyReferenceDetails.relationShipType,
          "doNotCall": "Y"
        }] : [])

      ]
    }

    UpdateCustomerInfo(payload, '02202', '02202')
  }



  return (
    <Grid id='referenceTab' >

      {loader && <ScreenLoader />}
      <DOFHeader identity={true}
        residence={true}
        income={true}
        reference={false}
        activeStep='Reference' />

      <Grid className={classes.referenceContainer}>
        <Grid className={classes.header}>
          <span style={{
            color: '#034694', fontFamily: 'openSans-bold', fontSize: '16px', fontWeight: '700',
            lineHeight: '21.79px', textAlign: "center"

          }}>
            Please add (1) family OR (2) non-family members as a reference
          </span>
        </Grid>


        {/* Reference Details 1  start */}

        <Grid className={classes.referenceSection}>
          <Typography>Reference {1} Details</Typography>
          <RACSelect
            loading={loader}
            inputLabel="Relationship"
            name="relationship"
            required={true}
            defaultValue={familyReferenceDetails.actualRelationShipType == familyReferenceDetails.relationShipType ? familyReferenceDetails.actualRelationShipType : familyReferenceDetails.relationShipType}
            options={relationshipOptions}
            onChange={(e) => handleInputChange('family', 'relationShipType', e.target.value)}
          />

          <RACTextbox
            isCurrency={false}
            required={true}
            type={"text"}
            inputlabel={'Reference  Name'}
            OnChange={(e) => handleInputChange('family', 'referenceName', e.target.value)}
            maxlength={25}
            value={familyReferenceDetails.actualReferenceName == familyReferenceDetails.referenceName ? familyReferenceDetails.actualReferenceName : familyReferenceDetails.referenceName}

          />

          <RACTextbox
            isCurrency={false}
            required={true}
            type={"number"}
            inputlabel={'Phone'}
            OnChange={(e) => handleInputChange('family', 'phoneNumber', e.target.value)}
            maxlength={25}
            value={familyReferenceDetails.actualPhoneNumber == familyReferenceDetails.phoneNumber ? familyReferenceDetails.actualPhoneNumber : familyReferenceDetails.phoneNumber}


          />

        </Grid>
        {/* Refernce Details 1 end */}



        {/* Reference Details 2  start*/}

        {
          nonFamilyReferenceRefcode.includes(familyReferenceDetails.relationShipType) ? <Grid className={classes.referenceSection}>
            <Typography>Reference {2} Details</Typography>
            <RACSelect
              loading={loader}
              inputLabel="Relationship"
              name="relationship"
              required={true}
              defaultValue={nonFamilyReferenceDetails.actualRelationShipType == nonFamilyReferenceDetails.relationShipType ? nonFamilyReferenceDetails.actualRelationShipType : nonFamilyReferenceDetails.relationShipType}
              options={relationshipOptions}
              onChange={(e) => handleInputChange('nonfamily', 'relationShipType', e.target.value)}
            />

            <RACTextbox
              isCurrency={false}
              required={true}
              type={"text"}
              inputlabel={'Reference  Name'}
              OnChange={(e) => handleInputChange('nonfamily', 'referenceName', e.target.value)}
              maxlength={25}
              value={nonFamilyReferenceDetails.actualReferenceName == nonFamilyReferenceDetails.referenceName ? nonFamilyReferenceDetails.actualReferenceName : nonFamilyReferenceDetails.referenceName}

            />

            <RACTextbox
              isCurrency={false}
              required={true}
              type={"number"}
              inputlabel={'Phone'}
              OnChange={(e) => handleInputChange('nonfamily', 'phoneNumber', e.target.value)}
              maxlength={25}
              value={nonFamilyReferenceDetails.actualPhoneNumber == nonFamilyReferenceDetails.phoneNumber ? nonFamilyReferenceDetails.actualPhoneNumber : nonFamilyReferenceDetails.phoneNumber}


            />

          </Grid> : null
        }


        {/* Refernce Details 2 end */}
        <Grid className={classes.buttonContainer}>
          <RACButton
            color='primary'
            variant='contained'
            style={{
              marginBottom: '15px',
              fontSize: '16px',
              width: '47%',
              minWidth: '282px',
              marginTop: '10px'


            }}
            className={`${classes.primaryButtonColor} `}
            onClick={() => {


              ReferenceSaveClick()
            }}
          >
            SAVE & CONTINUE
          </RACButton>
          <RACButton
            color='primary'
            variant='outlined'
            style={{
              marginBottom: '15px',
              fontSize: '16px',
              border: '1px solid #0f99d6',
              color: '#0f99d6',
              width: '47%',
              minWidth: '282px'
            }}
            onClick={() => {
              history.push(`/income/${customerId}/${approvalId}`)
            }}
          >

            Back
          </RACButton>
        </Grid>
        <Grid className={classes.note}>
          NOTE: If you prefer to continue with a store coworker, please call or visit your store.
        </Grid>
      </Grid>
    </Grid >
  );
};


export default ReferenceTab;