/* eslint-disable prettier/prettier */
import React from 'react';
import { Grid, Typography } from "@rentacenter/racstrap";
import { EMPTY_STRING } from "../../../constants/constants";
import { GovernmentIdType, IntellicheckGovtIDType } from "../../../constants/enums";
import { CustomerInfo } from "../../../interfaces/CustomerVerificationModel/DigitalOrderFormModel";
import { VerificationStyles } from '../../../styles/verificationStyles';
import { GetCustomerDetails, UpdateCustomerInfo, uploadVerificationDocuments } from '../../../api/user';

// Helper function to safely access nested properties and return a default value if the property is not found.
export const getValue = (data: any, path: string, defaultValue: string = EMPTY_STRING) => {
  // Split the path into an array of keys
  const pathParts = path.split('.');

  // Use reduce to traverse the path and access the nested value
  const value = pathParts.reduce((acc, part) => acc?.[part], data);

  // Return the found value or the default value if it's undefined or null
  return value ?? defaultValue;
};

// Helper function to safely access an array at a given path, returning an empty array if not found.
export const getArrayValue = (data: any, path: string) => {
  // Split the path into an array of keys
  const pathParts = path.split('.');

  // Traverse the object along the path to get the value
  const value = pathParts.reduce((acc, part) => acc?.[part], data);

  // Return the value if it's an array, otherwise return an empty array
  return Array.isArray(value) ? value : [];
};

// Function to determine the ID document type based on government ID type
export const getIdDocumentType = (customerDetails: CustomerInfo) => {
  const governmentIdType = customerDetails?.taxInfo?.governmentIdType;

  switch (governmentIdType) {
    case GovernmentIdType.DL:
    case GovernmentIdType.UDL:
      return IntellicheckGovtIDType.na_dl;
    case GovernmentIdType.PASS:
    case GovernmentIdType.UPASS:
      return IntellicheckGovtIDType.passport;
    default:
      return IntellicheckGovtIDType.other;
  }
};

/* 
  This function is used to dynamically bind headings based on the Tab. 
  Just provide the tab name, and it will return the heading.
*/
export const getTabsHeading = (tabName: string) => {
  const classes: any = VerificationStyles();
  return (
    <Grid className={classes.headingWidth}>
      <Typography className={`${classes.heading}`}>{`Please give us some details about your ${tabName}`}</Typography>
    </Grid>
  )
}

export const getNoteText = () => {
  const classes: any = VerificationStyles();
  return (
    <Grid className={`${classes.w85p}`}>
      <Typography className={`${classes.font14px}`}><span className={`${classes.boldFont1}`}>NOTE: </span>If you prefer to continue with a store coworker, please call or visit your store.</Typography>
    </Grid>
  )
}
export interface dropdown {

  label: string;

  value: string;

  paydayid?: number;

}





export const buildDropdownList = (optionsList: any): dropdown[] => {

  const options: dropdown[] = [{ value: '', label: 'Select' }];

  const referenceOptions = optionsList.filter((obj: any) => obj.referenceCode != 'REL')
  // eslint-disable-next-line no-console
  console.log("referenceOptions", referenceOptions)

  if (

    referenceOptions !== undefined &&

    referenceOptions !== null &&

    referenceOptions.length > 0

  ) {

    referenceOptions.map((val: any) => {

      options.push({ label: val.description, value: val.referenceCode });

    });

  }



  return options;

};

export const updateCustomerApproval = async (tabName: string, decisionId: string, customerId: string) => {
  // eslint-disable-next-line no-console
  console.log('Tab Name', tabName);

  const payload = {
    customerId: customerId,
    deDecisionId: decisionId,
    isManageApproval: true,
    verifyCode: "1",
    verificationDocuments: [
      {
        verificationStatus: "PENDREW",
        verified: "N",
        verifyType: tabName,
        modifiedBy: "DOF",
      },
    ],
  };
  await UpdateCustomerInfo(payload, '02202', '02202')
}

export const uploadDocuments = async (decisionId: string, documents: any) => {
  const payload: any = {
    isVerificationDocument: true,
    customerApprovalId: decisionId,
    documents: documents,
  };

  const uploadDocRes: any = await uploadVerificationDocuments(payload, '02202', '02202');

  // eslint-disable-next-line no-console
  console.log('Upload Documents Res', uploadDocRes)

  if (uploadDocRes?.status == 200) {
    // eslint-disable-next-line no-console
    console.log('Upload document sucess', uploadDocRes?.data)
  } else {
    // eslint-disable-next-line no-console
    console.log('Something went wrong document upload', uploadDocRes?.data)
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const checkRACPadCustomer = async (gcid: string, setRacPadCustomerDetail: any, setCustomerId: any) => {

  const getCustomerDetailResp: any = await GetCustomerDetails({ GcId: gcid }, '02202', '02202');

  // eslint-disable-next-line no-console
  console.log('Detail Service Response', getCustomerDetailResp, getCustomerDetailResp?.status == 200, getCustomerDetailResp?.data);

  if (getCustomerDetailResp && getCustomerDetailResp?.status == 200 && getCustomerDetailResp?.data) {
    // eslint-disable-next-line no-console
    console.log('Inside the getCustomerDetailResp', getCustomerDetailResp)
    setRacPadCustomerDetail(getCustomerDetailResp.data);
    setCustomerId(getCustomerDetailResp.data?.customerId);
    return getCustomerDetailResp.data?.customerId
  } else {
    return ''
  }

}
