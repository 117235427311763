/* eslint-disable */

export enum AppRoute {
  startingPage = '/',
  payfoneprefill = '/payfoneprefill',
  basicInfo = '/basicinfo',
  employerInfo = '/employer',
  residenceInfo = '/residence',
  referenceInfo = '/reference',
  reviewInfo = '/reviewinfo',
  ApprovalPage = '/ApprovalPage',
  identifyVerify = '/IdentityVerify',
  userInfo = '/userInfo',

  dofStartPage ='/dof/verification/:approvalId',
  indentitytab='/identity/:customerId/:approvalId',
  residenceTab ='/residence/:customerId/:approvalId',
  incomeTab ='/income/:customerId/:approvalId',
  reference ='/reference/:customerId/:approvalId',
  welcomepage ='/welcome/:approvalId',
  dofAuthentication ='/otp/auth/:approvalId',
  reviewInformation ='/review/details/:customerId/:approvalId',
  dofSubmitted ='/dof/submission/:customerId/:approvalId'

}
