/* eslint-disable */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Grid, LinearProgress, Link, List, ListItem, Paper, RACButton, RACRadio, RACSelect, RACTextbox, Typography } from '@rentacenter/racstrap';
import React, { useContext, useState } from 'react';
import { DigitalOrderFormContext } from '../Context/DigitialOrderFormContext';
import { ReactComponent as CameraIcon } from './../../../images/cameraIcon.svg';
import { ReactComponent as UploadIcon } from './../../../images/uploadImage.svg';
import { ReactComponent as ImageIcon } from './../../../images/uploadImage.svg';
import { ReactComponent as PdfIcon } from './../../../images/pdf-icon.svg';
import { ReactComponent as DeleteIcon } from './../../../images/delete-icon.svg';
import { ReactComponent as UpArrowIcon } from './../../../images/upArrowIcon.svg';
import { ReactComponent as DownArrowIcon } from './../../../images/downArrowIcon.svg';

import { VerificationStyles } from '../../../styles/verificationStyles';
import DOFHeader from './DOFHeader';
import { getNoteText, getTabsHeading, updateCustomerApproval, uploadDocuments } from '../Common/HelperFunctions';
import { APPLICATION_PDF, RESIDENCE_TAB_DOCUMENT_LIST } from '../../../constants/constants';
import { BooleanRepresentation } from '../../../constants/enums';
import { useHistory, useParams } from 'react-router-dom';
import { ResidenceTabContext } from '../Context/ResidenceDetailsContext';
import { UpdateCustomerInfo } from '../../../api/user';

export const ResidenceTab = () => {
  const { businessFlow } = useContext(DigitalOrderFormContext);
  const { landlordResidence, setLandlordResidence, manualEntryEnabled, setManualEntryEnabled, residenceType, setResidenceType } = useContext(ResidenceTabContext)
  const classes: any = VerificationStyles();
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const [base64Files, setBase64Files] = useState<string[]>([]);
  const [imageBase64, setImageBase64] = useState<string | null>(null);
  const [showSecondaryDoc, setShowSecondaryDoc] = useState<boolean>(false);
  const [showResidenceVerfDocument, setShowResidenceVerfDocument] = useState<boolean>(false);
  const [fileSize, setFileSize] = useState<string>('');
  const [fileType, setFileType] = useState<string>('');
  const history = useHistory();
  const { customerId, approvalId } = useParams<any>();


  const timeAtCurrentAddressTypes = [{
    label: 'Select', value: ''
  },
  {
    label: 'Less than 1 year', value: '1'
  },
  {
    label: '1-2 Years', value: '2'
  },
  {
    label: '3-4 Years', value: '3'
  },
  {
    label: ' 5+ Years', value: '4'
  }]

  const openCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      const videoElement = document.createElement("video");
      videoElement.srcObject = stream;
      videoElement.play();

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      // Create a text message element
      const messageOverlay = document.createElement("div");
      messageOverlay.innerText = "Capture the document within the red area";
      messageOverlay.style.position = "absolute";
      messageOverlay.style.top = "7.8%"; // Adjust this to position the message
      messageOverlay.style.left = "50%";
      // messageOverlay.style.width = adjustButtonWidth();
      messageOverlay.style.transform = "translateX(-50%)"; // Center horizontally
      messageOverlay.style.fontSize = "14px"; // Font size for visibility
      messageOverlay.style.fontWeight = "bold";
      messageOverlay.style.color = "#fff"; // White text color
      messageOverlay.style.textAlign = "center"; // Center the text
      messageOverlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)"; // Semi-transparent background for better readability
      messageOverlay.style.padding = "10px"; // Some padding for text clarity
      messageOverlay.style.borderRadius = "5px";

      const videoContainer = document.createElement("div");
      videoContainer.style.position = "fixed";
      videoContainer.style.top = "0";
      videoContainer.style.left = "0";
      videoContainer.style.width = "100%";
      videoContainer.style.height = "100%";
      videoContainer.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
      videoContainer.style.display = "flex";
      videoContainer.style.flexDirection = "column";
      videoContainer.style.justifyContent = "center";
      videoContainer.style.alignItems = "center";
      videoContainer.style.zIndex = "1000";

      // Square overlay style
      const squareOverlay = document.createElement("div");
      squareOverlay.style.position = "absolute";
      squareOverlay.style.border = "2px solid #ff0000"; // Red border for visibility
      squareOverlay.style.width = "300px"; // Size of the crop area (adjust as needed)
      squareOverlay.style.height = "350px"; // Same as above
      squareOverlay.style.top = "47%";
      squareOverlay.style.left = "50%";
      squareOverlay.style.transform = "translate(-50%, -50%)"; // Center the square

      const captureButton = document.createElement("button");
      captureButton.innerText = "Capture Document";
      captureButton.style.padding = "10px 20px";
      captureButton.style.width = '250px';
      captureButton.style.marginTop = "10px";
      captureButton.style.color = "#fff";
      captureButton.style.backgroundColor = "#0f99d6";
      captureButton.style.border = "none";
      captureButton.style.cursor = "pointer";
      captureButton.style.fontSize = "14px";
      captureButton.style.borderRadius = "5px";

      // window.addEventListener("resize", adjustButtonWidth);

      captureButton.onclick = async () => {
        // Get the position and size of the square overlay relative to the viewport
        const squareBounds = squareOverlay.getBoundingClientRect();

        // Get the position and size of the video element
        const videoBounds = videoElement.getBoundingClientRect();

        // Calculate the offset of the squareOverlay within the video element (considering scaling)
        const offsetX = squareBounds.left - videoBounds.left; // Adjust X based on video position
        const offsetY = squareBounds.top - videoBounds.top; // Adjust Y based on video position

        // Set the canvas size to match the dimensions of the square overlay
        canvas.width = squareBounds.width;
        canvas.height = squareBounds.height;

        // Capture the part of the video inside the square
        context?.drawImage(
          videoElement,
          offsetX, // Start at the adjusted X position inside the video element
          offsetY, // Start at the adjusted Y position inside the video element
          squareBounds.width, // Width of the square (same as canvas)
          squareBounds.height, // Height of the square (same as canvas)
          0,
          0, // Draw to the canvas starting at (0, 0)
          squareBounds.width, // Canvas width
          squareBounds.height // Canvas height
        );

        // Convert the captured part to Base64
        const imageData = canvas.toDataURL("image/png");

        // Optionally, show the captured image to the user for confirmation
        const previewImage = document.createElement("img");
        previewImage.src = imageData;
        previewImage.style.maxWidth = "80%"; // Style the preview image
        previewImage.style.maxHeight = "80%";

        // Append the image preview to the body (or a modal if you prefer)
        // document.body.appendChild(previewImage);

        // Stop the camera and hide the video container (optional)
        // stream.getTracks().forEach((track) => track.stop()); // Uncomment if you want to stop the camera

        // Show the cropped image for confirmation
        const confirmationContainer = document.createElement("div");
        confirmationContainer.style.position = "fixed";
        confirmationContainer.style.top = "0";
        confirmationContainer.style.left = "0";
        confirmationContainer.style.width = "100%";
        confirmationContainer.style.height = "100%";
        confirmationContainer.style.backgroundColor = "rgba(0, 0, 0, 0.8)";
        confirmationContainer.style.display = "flex";
        confirmationContainer.style.flexDirection = "column";
        confirmationContainer.style.alignItems = "center";
        confirmationContainer.style.justifyContent = "center";
        confirmationContainer.style.zIndex = "1001";

        const imagePreview = document.createElement("img");
        imagePreview.src = imageData;
        imagePreview.style.maxWidth = "80%";
        imagePreview.style.maxHeight = "80%";
        imagePreview.style.marginBottom = "15px";

        const confirmButton = document.createElement("button");
        confirmButton.innerText = "Confirm";
        confirmButton.style.width = "100%";
        confirmButton.style.padding = "10px 20px";
        confirmButton.style.color = "#fff";
        confirmButton.style.backgroundColor = "#0f99d6";
        confirmButton.style.border = "none";
        confirmButton.style.cursor = "pointer";
        confirmButton.style.fontSize = "14px";
        confirmButton.style.borderRadius = "5px";

        const retakeButton = document.createElement("button");
        retakeButton.innerText = "Retake";
        retakeButton.style.width = "100%";
        retakeButton.style.padding = "10px 20px";
        retakeButton.style.marginTop = "10px";
        retakeButton.style.color = "#0f99d6";
        retakeButton.style.backgroundColor = "#fff";
        retakeButton.style.border = "none";
        retakeButton.style.cursor = "pointer";
        retakeButton.style.fontSize = "14px";
        retakeButton.style.borderRadius = "5px";

        confirmButton.onclick = async () => {
          // Convert captured image to PDF and Base64 (this function should be defined)
          const pdfBase64 = await convertImageToPdfBase64(imageData);
          console.log("PDF Base64:", pdfBase64);

          // Store Base64 in state variable
          setImageBase64(pdfBase64);

          // Stop camera and close the popup
          stream.getTracks().forEach((track) => track.stop());
          document.body.removeChild(confirmationContainer);
          setShowResidenceVerfDocument(true);
          setFileType("image/png");
          const fileSize = Math.round((pdfBase64.length * 3) / 4);
          const fileSizeKB = (fileSize / 1024).toFixed(2);
          setFileSize(`${fileSizeKB} KB`);
        };

        retakeButton.onclick = () => {
          // Hide confirmation and retake the image
          document.body.removeChild(confirmationContainer);
          document.body.appendChild(videoContainer); // Show video again
          videoElement.play();
        };

        const confirmationButtonContainer = document.createElement("div");
        confirmationButtonContainer.style.display = "flex";
        confirmationButtonContainer.style.flexDirection = "column";
        confirmationButtonContainer.style.width = '250px';
        // confirmationButtonContainer.style.f = 'space-between';
        confirmationButtonContainer.appendChild(confirmButton);
        confirmationButtonContainer.appendChild(retakeButton);

        confirmationContainer.appendChild(imagePreview);
        confirmationContainer.appendChild(confirmationButtonContainer);

        // Remove video container (optional, you can show it again later if needed)
        document.body.removeChild(videoContainer);
        document.body.appendChild(confirmationContainer); // Show the confirmation screen
      };

      // Append the message overlay to the video container
      videoContainer.appendChild(messageOverlay);
      videoContainer.appendChild(videoElement);
      videoContainer.appendChild(captureButton);
      videoContainer.appendChild(squareOverlay); // Add the square overlay
      document.body.appendChild(videoContainer);
    } catch (error: any) {
      alert("Unable to access the camera: " + error.message);
    }
  };

  const convertImageToPdfBase64 = async (
    imageBase64: string
  ): Promise<string> => {
    return new Promise((resolve, reject) => {
      const pdfCanvas = document.createElement("canvas");
      const pdfContext = pdfCanvas.getContext("2d");

      const img = new Image();
      img.src = imageBase64;

      img.onload = () => {
        const pdfWidth = 595.28; // A4 width in points
        const pdfHeight = (img.height / img.width) * pdfWidth; // Maintain aspect ratio

        pdfCanvas.width = pdfWidth;
        pdfCanvas.height = pdfHeight;

        pdfContext?.drawImage(img, 0, 0, pdfWidth, pdfHeight);

        // Generate PDF using Blob
        const pdfBlob = generatePdfBlob(pdfCanvas);

        // Convert PDF Blob to Base64
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(reader.result as string); // Return Base64 PDF
        };
        reader.onerror = (err) => reject(err);
        reader.readAsDataURL(pdfBlob); // Ensure it's read as a PDF
      };

      img.onerror = () => reject(new Error("Failed to load image"));
    });
  };

  // Helper function to generate a PDF Blob
  const generatePdfBlob = (canvas: HTMLCanvasElement): Blob => {
    const pdfHeader: any = [
      "%PDF-1.4\n",
      "1 0 obj << /Type /Catalog /Pages 2 0 R >> endobj\n",
      "2 0 obj << /Type /Pages /Kids [3 0 R] /Count 1 >> endobj\n",
      "3 0 obj << /Type /Page /Parent 2 0 R /Resources << /XObject << /Im1 4 0 R >> >>\n",
      "/MediaBox [0 0 595.28 841.89] /Contents 5 0 R >> endobj\n",
    ];

    const pdfFooter = "%%EOF";

    // Image to PDF object stream
    const imageStream = canvas
      .toDataURL("image/png")
      .replace("data:image/png;base64,", "");
    const imageBytes = atob(imageStream);
    const imageData = new Uint8Array(imageBytes.length);

    for (let i = 0; i < imageBytes.length; i++) {
      imageData[i] = imageBytes.charCodeAt(i);
    }

    const pdfStream: any = new Blob(
      [
        ...pdfHeader,
        "/XObject << /Im1 << /Subtype /Image /Type /XObject /Width 595 /Height 841 /ColorSpace /DeviceRGB /BitsPerComponent 8 /Length ",
        imageData.length,
        " >> stream\n",
        imageData,
        "\nendstream endobj\n",
        pdfFooter,
      ],
      { type: APPLICATION_PDF }
    );

    return pdfStream;
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      handleFiles(Array.from(files));
    }
  };

  const handleFiles = async (files: File[]) => {
    const validFiles = files.filter((file) =>
      ["image/png", "image/jpeg", APPLICATION_PDF].includes(file.type)
    );

    setUploadedFiles((prev) => [...prev, ...validFiles]);

    // Convert each file into a Base64 string
    const base64Promises = validFiles.map((file) => convertToBase64(file));
    const base64Results = await Promise.all(base64Promises);

    // Save Base64 results to state
    setBase64Files((prev) => [...prev, ...base64Results]);

    handleFileDetails(files);
  };
  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    handleFiles(Array.from(event.dataTransfer.files));
  };

  const handleFileDetails = (files: any) => {
    const fileSizeInBytes = files[0].size;  // Get file size in bytes
    setFileType(files[0].type); // Get file type

    // Convert bytes to KB, MB, or GB
    let fileSize: any = fileSizeInBytes;
    let sizeUnit = 'bytes';

    if (fileSizeInBytes >= 1024) {
      fileSize = fileSizeInBytes / 1024;
      sizeUnit = 'KB';
    }

    if (fileSize >= 1024) {
      fileSize = fileSize / 1024;
      sizeUnit = 'MB';
    }

    if (fileSize >= 1024) {
      fileSize = fileSize / 1024;
      sizeUnit = 'GB';
    }

    // Round the file size to 2 decimal places
    fileSize = fileSize.toFixed(2);

    // Set the file size to the state
    setFileSize(`${fileSize} ${sizeUnit}`);

    setShowResidenceVerfDocument(true);
  }

  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = () => reject(new Error("Failed to convert file."));
      reader.readAsDataURL(file);
    });
  };


  // handleInputChange function

  const handleInputChange = (field?: any, value?: any) => {
    setLandlordResidence({ ...landlordResidence, [field]: value.trim() })
  };

  //  Residence Tab Save Click 

  const ResidenceSaveClick = async () => {
    const payload: any = {
      "customerId": customerId,
      "landlordReferences": [
        {
          "landlordFirstName": landlordResidence?.landLordName,
          "landlordLastName": "Landlordw",
          "phoneNumber": landlordResidence?.landLordPhoneNumber,
          "moveInDate": "2024-08-07",
          "active": "Y",

          "leaseLengthYears": "0.000000",
          "leaseLengthMonths": "0.000000",
          "leaseTerm": null,
          "leaseCompanyName": null,
          "phoneExtension": null,
          "addressLine1": null,
          "addressLine2": null,
          "city": null,
          "state": null,
          "postalCode": "",
          "leaseHolderName": null,
          "bestTimeToCall": null,
          "verifiedDate": ""
        }
      ],
      "mortgageCompanyName": null,
      "monthlyMortgagePayment": null,
      "residenceSince": null,
      "residenceType": "RENT"
    }

    history.push(`/income/${customerId}/${approvalId}`);
    updateCustomerApproval("RESIDENCE", approvalId, customerId);
    uploadDocuments(approvalId, [
      {
        documentType: "RESDOC",
        file: imageBase64
          ? imageBase64.replace(/^data:application\/pdf;base64,/, "")
          : base64Files?.[base64Files.length - 1],
      },
    ]);
    landlordResidence?.landLordName ? UpdateCustomerInfo(payload, '02202', '02202') : null


  }
  return (
    <Grid>
      {/*DOFHeader is used to dynamically display the RACLogo and Progress Bar based on the Tab and BusinessType. 
      Simply provide the activeStep as your current tab, mark the completed tabs as true, and set the current and 
      next step tabs as false.*/}
      <Grid>
        <DOFHeader
          identity={true}
          residence={false}
          income={false}
          reference={false}
          activeStep="Residence"
        />
      </Grid>
      {/*getTabsHeading is used to dynamically bind headings based on the Tab. Just provide the tab name, 
      and it will return the heading.*/}
      <Grid>{getTabsHeading("residence")}</Grid>
      <Grid className={`${classes.leftAlign} ${classes.tabGridStyle}`}>
        <Grid className={`${classes.leftAlign} ${classes.minWidth38p} ${classes.p16px}`}>

          <Grid>
            <Typography >
              Do you rent or own? <span style={{ color: 'red' }}>*</span>
            </Typography>

            <RACRadio
              // disabled={residenceType != 'RENT'}
              checked={residenceType == "RENT"}
              onChange={() => {
                setResidenceType('RENT')
              }}
              value={""}
              label="Rent"
            />

            <RACRadio
              // disabled={residenceType != 'OWN'}
              checked={residenceType == "OWN"}
              onChange={() => {
                setResidenceType('OWN')
              }}
              value={""}
              label="Own"
            />
          </Grid>
          <Typography className={`${classes.boldFont1}`}>Proof of Residence</Typography>
          <Typography className={`${classes.leftAlign} ${classes.pl16px} ${classes.pt10px}`}>
            Accepted proof of residence documents, listed below (choose one):
          </Typography>
          <ul className={`${classes.pl35px}`}>
            {RESIDENCE_TAB_DOCUMENT_LIST.map((doc: any) =>
              doc.type == BooleanRepresentation.Primary ?
                (<li className={`${classes.pb5px}`}>{doc.value}</li>) :
                doc.type == BooleanRepresentation.Secondary && showSecondaryDoc ?
                  (<li className={`${classes.pb5px}`}>{doc.value}</li>) : null
            )}
          </ul>
          <Typography className={`${classes.cursorPointer} ${classes.leftAlign} ${classes.pl16px} ${classes.primaryFontColor} ${classes.underline}`} onClick={() => setShowSecondaryDoc(!showSecondaryDoc)}>{showSecondaryDoc ? `Hide Document` : `View More Documents Examples`}</Typography>
        </Grid>
      </Grid>
      <Grid className={`${classes.tabGridStyle} ${classes.width100p}`}>
        <Grid className={`${classes.tabGridStyle}`}>
          <Typography variant="h6" className={`${classes.font16px} ${classes.p10px}`}>
            Choose any method below
          </Typography>

          <RACButton
            variant="outlined"
            color="primary"
            onClick={openCamera}
            startIcon={<CameraIcon />}
            className={`${classes.mb15px} ${classes.buttonBorderColor} ${classes.blackFont} ${classes.font16px} ${classes.w91p}`}
          >
            OPEN CAMERA AND TAKE PHOTO
          </RACButton>

          <Paper
            className={`${classes.drapNDropStyling} ${classes.w91p} ${classes.mb20px}`}
            onDragOver={(e) => e.preventDefault()}
            onDrop={handleDrop}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <UploadIcon className={`${classes.mt20px} ${classes.mb15px}`} />
            </Box>
            <label>
              <input
                type="file"
                accept=".png,.jpeg,.jpg,.pdf"
                multiple
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
              <Typography
                variant="body2"
                component="span"
                color="primary"
                className={`${classes.underline} ${classes.cursorPointer} ${classes.primaryFontColor} ${classes.font16px}`}
              >
                Choose a file or drag it here
              </Typography>
            </label>
            <Typography variant="body2" className={`${classes.mt10px} ${classes.lightGreyColor} ${classes.font14px} ${classes.p10px}`}>
              JPEG, PNG, PDF up to 50MB
            </Typography>
          </Paper>

          {showResidenceVerfDocument &&
            <Grid style={{
              width: '100%',
              textAlign: 'left',
              padding: '10px',
              display: 'flex',
              paddingTop: '0px'
            }}>
              {fileType && (fileType == 'image/png' || fileType == 'image/jpg') && <ImageIcon style={{ width: '20px', height: '20px' }} />}
              {fileType && fileType == APPLICATION_PDF && <PdfIcon style={{ width: '20px', height: '20px' }} />}
              <Typography className={`${classes.primaryFontColor} ${classes.pl10px}`}>Proof of Residence <span className={`${classes.fileSizeColor}`}>{fileSize}</span></Typography>
              <DeleteIcon style={{ width: '15px', height: '15px', marginLeft: '10px', marginTop: '3px' }} onClick={() => setShowResidenceVerfDocument(false)} />
            </Grid>}

          {residenceType == 'RENT' ? <>
            <Grid>
              <Typography style={{ marginBottom: '5px' }}>
                <span className={classes.manulEntry} onClick={() => { setManualEntryEnabled(!manualEntryEnabled) }}>
                  Enter details manually {manualEntryEnabled ? <DownArrowIcon /> : <UpArrowIcon />}
                </span>
              </Typography>
            </Grid>
            {manualEntryEnabled ? <Grid className={classes.residenceManulEntry}>
              <RACTextbox
                isCurrency={false}
                required={true}
                type={"text"}
                inputlabel={'Landlord’s / Apartment Name '}
                OnChange={(e) => handleInputChange('landLordName', e.target.value)}
                maxlength={25}
                value={landlordResidence.actualLandlordName == landlordResidence.landLordName ? landlordResidence.actualLandlordName : landlordResidence.landLordName}
              />
              <RACSelect
                inputLabel="Time at Current Address "
                name="timeatCurrentAddress"
                required={true}
                defaultValue={landlordResidence.actualTimeAtCurrentAddress == landlordResidence.timeAtCurrentAddress ? landlordResidence.actualTimeAtCurrentAddress : landlordResidence.timeAtCurrentAddress}
                options={timeAtCurrentAddressTypes}
                onChange={(e) => handleInputChange('timeAtCurrentAddress', e.target.value)}
              />

              <RACTextbox
                isCurrency={false}
                required={true}
                type={"number"}
                inputlabel={'Phone'}
                OnChange={(e) => handleInputChange('landLordPhoneNumber', e.target.value)}
                maxlength={10}
                value={landlordResidence.actualLandlordPhoneNumber == landlordResidence.landLordPhoneNumber ? landlordResidence.actualLandlordPhoneNumber : landlordResidence.landLordPhoneNumber}

              />
            </Grid> : null}
          </> : null}



          <RACButton
            variant="contained"
            color="primary"
            onClick={() => {
              ResidenceSaveClick()

            }}
            className={`${classes.mb15px} ${classes.font16px} ${classes.primaryButtonColor} ${classes.w91p}`}
          >
            SAVE & CONTINUE
          </RACButton>

          {getNoteText()}
        </Grid>
      </Grid>
    </Grid>
  );
};