/* eslint-disable prettier/prettier */
import {
    Grid,
    RACButton,
    RACModalCard,
    Typography,
  } from '@rentacenter/racstrap';
  import { ReactComponent as AlertIcon } from './../../../images/no-records-found.svg';
  import { VerificationStyles } from "./../../../styles/verificationStyles";
  
  import React from 'react';
  
  export default function MessagePopup(props: any) {
    const message = props.value.message;
    const classes: any = VerificationStyles();

    return (
      <RACModalCard
        isOpen={true}
        closeIcon={false}
        maxWidth="xs"
        borderRadius="25px !important"
      >
        <div
          id="initialpayment"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          aria-hidden="true"
        >
          <Grid className={classes.centerAlign}>
            <AlertIcon></AlertIcon>
            <Typography className={`${classes.formLabel}  ${classes.mt10px}`}>
              {message}
            </Typography>
          </Grid>
          <Grid
            item
            md={12}
            className={`${classes.mt20px} ${classes.pb4} ${classes.centerAlign}`}
          >
            <RACButton
              className={classes.mx1}
              variant="contained"
              color="primary"
              onClick={() => {
                props.value.setFunction(false);
                props.value.cleanupFunction('');
              }}
            >
              Ok
            </RACButton>
          </Grid>
        </div>
      </RACModalCard>
    );
  }
  