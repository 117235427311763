/* eslint-disable prettier/prettier */
import React, { createContext, useState } from 'react';
import { CustomerInfo, IntellicheckCustomerDetails } from './../../../interfaces/CustomerVerificationModel/DigitalOrderFormModel';
import { CUSTOMER_INFO_TEMPLATE, INTELLICHECK_DETAILS_TEMPLATE } from './../../../constants/constants';
export const DigitalOrderFormContext = createContext<any>('');
export function DigitalOrderFormContextProvider(props: any) {
    const [customerDetails, setCustomerDetails] = useState<CustomerInfo>(CUSTOMER_INFO_TEMPLATE);
    const [decisionEngineDetails, setDecisionEngineDetails] = useState<any>('');
    const [intellicheckVerfDetails, setIntellicheckVerfDetails] = useState<IntellicheckCustomerDetails>(INTELLICHECK_DETAILS_TEMPLATE);
    const [businessFlow, setBusinessFlow] = useState<string>('DOF');
    const [racpadCustomerDetail, setRacPadCustomerDetail] = useState<any>('');
    const [customerId, setCustomerId] = useState<string>('');
    return (
        <div>
            <DigitalOrderFormContext.Provider
                value={{
                    customerDetails, setCustomerDetails,
                    decisionEngineDetails, setDecisionEngineDetails,
                    intellicheckVerfDetails, setIntellicheckVerfDetails,
                    businessFlow, setBusinessFlow,
                    racpadCustomerDetail, setRacPadCustomerDetail,
                    customerId, setCustomerId
                }}
            >
                {props.children}
            </DigitalOrderFormContext.Provider>
        </div>
    );
}
