/* eslint-disable prettier/prettier */
import React, { createContext, useState } from 'react';
export const ResidenceTabContext = createContext<any>('');
export function ResidenceTabContextProvider(props: any) {
    const [landlordResidence, setLandlordResidence] = useState({
        actualLandlordName: '',
        actualTimeAtCurrentAddress: '',
        actualLandlordPhoneNumber: '',
        landLordName: '',
        timeAtCurrentAddress: '',
        landLordPhoneNumber: '',
    })
    const [residenceType, setResidenceType] = useState<string>('RENT')

    const [manualEntryEnabled, setManualEntryEnabled] = useState(true)

    return (
        <div>
            <ResidenceTabContext.Provider
                value={{
                    landlordResidence, setLandlordResidence,
                    manualEntryEnabled, setManualEntryEnabled,
                    residenceType, setResidenceType

                }}
            >
                {props.children}
            </ResidenceTabContext.Provider>
        </div>
    );
}
