/* eslint-disable prettier/prettier */
/* eslint-disable no-console */

import {
  GetCustomerDetails,
  getIntellicheckVerificationDetails,
} from "../../../api/user";
import {
  BUSINESS_UNIT,
  CUSTOMER_INFO_TEMPLATE,
  EMPTY_STRING,
  INTELLICHECK_DETAILS_TEMPLATE,
} from "../../../constants/constants";
import {
  Address,
  CommunicationConsent,
  CustomerInfo,
  CustomerRoute,
  DecryptDataPayload,
  DecryptedData,
  GetIntellicheckDetailsPayload,
  IntellicheckCustomerDetails,
  Phone,
} from "../../../interfaces/CustomerVerificationModel/DigitalOrderFormModel";
import { getArrayValue, getValue, uploadDocuments } from "./HelperFunctions";
import crypto, { createDecipheriv } from "crypto";

export const getCustomerDetails = async (
  customerId: string,
  setCustomerDetails: React.Dispatch<React.SetStateAction<CustomerInfo>>
): Promise<CustomerInfo> => {
  const getCustomerRequest = {
    getCustomerDetails: true,
    customerId,
  };

  let customerData: any = await GetCustomerDetails(
    getCustomerRequest,
    "02202",
    "02202"
  );

  console.log("getCustomerDetails Response", customerData);

  if (customerData?.status === 200 && customerData?.data) {
    const decryptDataPayload: DecryptDataPayload = {
      decryptData: {},
      encryptKey: crypto.randomBytes(16).toString("hex"),
      ivKey: crypto.randomBytes(8).toString("hex"),
    };

    if (customerData?.data?.dateOfBirthEnc)
      decryptDataPayload.decryptData.dateOfBirth =
        customerData.data.dateOfBirthEnc;
    if (customerData?.data?.taxIdEnc)
      decryptDataPayload.decryptData.taxIdEnc = customerData.data.taxIdEnc;
    if (customerData?.data?.governmentId)
      decryptDataPayload.decryptData.governmentIdEnc =
        customerData.data.governmentIdEnc;

    const decryptDataResponse = await GetCustomerDetails(
      decryptDataPayload,
      "02202",
      "02202"
    );
    console.log("decryptResponse", decryptDataResponse);

    const decryptedData: DecryptedData = await handleDecryption(
      decryptDataPayload,
      decryptDataResponse
    );
    customerData = customerData?.data;
    const customerInfo: CustomerInfo = await mapCustomerDataToInfo(
      customerData,
      decryptedData
    );

    console.log("getCustomerDetails", customerInfo);
    setCustomerDetails(customerInfo);
    return customerInfo;
  } else {
    console.log("Customer data retrieval failed");
    setCustomerDetails(CUSTOMER_INFO_TEMPLATE);
    return CUSTOMER_INFO_TEMPLATE;
  }
};

const decryptCustomerData = async (
  encryptKey: string,
  ivKey: string,
  encryptedData: any
) => {
  if (encryptedData) {
    return getData(encryptKey, ivKey, encryptedData);
  }
  return EMPTY_STRING;
};

const handleDecryption = async (
  decryptDataPayload: DecryptDataPayload,
  decryptDataResponse: any
): Promise<DecryptedData> => {
  if (
    decryptDataResponse?.status == 200 &&
    decryptDataResponse?.data?.decryptData
  ) {
    return {
      dateOfBirth: await decryptCustomerData(
        decryptDataPayload.encryptKey,
        decryptDataPayload.ivKey,
        decryptDataResponse?.data?.decryptData?.dateOfBirth
      ),
      taxId: await decryptCustomerData(
        decryptDataPayload.encryptKey,
        decryptDataPayload.ivKey,
        decryptDataResponse?.data?.decryptData?.taxIdEnc
      ),
      governmentId: await decryptCustomerData(
        decryptDataPayload.encryptKey,
        decryptDataPayload.ivKey,
        decryptDataResponse?.data?.decryptData?.governmentIdEnc
      ),
    };
  } else {
    return {
      dateOfBirth: EMPTY_STRING,
      taxId: EMPTY_STRING,
      governmentId: EMPTY_STRING,
    };
  }
};

const mapCustomerDataToInfo = (
  customerData: any,
  decryptedData: DecryptedData
): CustomerInfo => {
  console.log("Customer data inside mapper", customerData);
  return {
    personalInfo: {
      customerId: getValue(customerData, "customerId"),
      globalCustomerId: getValue(customerData, "globalCustomerId"),
      firstName: getValue(customerData, "firstName"),
      lastName: getValue(customerData, "lastName"),
      middleInitial: getValue(customerData, "middleInitial"),
      dateOfBirth: getValue(customerData, "dateOfBirth"),
      dateOfBirthEnc: getValue(customerData, "dateOfBirthEnc"),
      dateOfBirthDec: decryptedData.dateOfBirth,
      emailAddress: getValue(customerData, "emailAddress"),
      preferredContactMethod: getValue(customerData, "preferredContactMethod"),
      preferredLanguage: getValue(customerData, "preferredLanguage"),
      salutation: getValue(customerData, "salutation"),
      salutationDesc: getValue(customerData, "salutationDesc"),
      suffix: getValue(customerData, "suffix"),
      suffixDesc: getValue(customerData, "suffixDesc"),
    },
    taxInfo: {
      taxId: getValue(customerData, "taxId"),
      taxIdEnc: getValue(customerData, "taxIdEnc"),
      taxIdDec: decryptedData.taxId,
      taxIdType: getValue(customerData, "taxIdType"),
      governmentId: getValue(customerData, "governmentId"),
      governmentIdEnc: getValue(customerData, "governmentIdEnc"),
      governmentIdDec: decryptedData.governmentId,
      governmentIdType: getValue(customerData, "governmentIdType"),
      governmentIdTypeDesc: getValue(customerData, "governmentIdTypeDesc"),
      governmentIdIssuedState: getValue(
        customerData,
        "governmentIdIssuedState"
      ),
    },
    solicitationPreferences: {
      phoneSolicitationAllowed: getValue(
        customerData,
        "phoneSolicitationAllowed"
      ),
      emailSolicitationAllowed: getValue(
        customerData,
        "emailSolicitationAllowed"
      ),
      mailSolicitationAllowed: getValue(
        customerData,
        "mailSolicitationAllowed"
      ),
      textSolicitationAllowed: getValue(
        customerData,
        "textSolicitationAllowed"
      ),
      paymentReminderCallsAllowed: getValue(
        customerData,
        "paymentReminderCallsAllowed"
      ),
      isNDNCOpted: getValue(customerData, "isNDNCOpted"),
      isTrnsMsgAllowed: getValue(customerData, "isTrnsMsgAllowed"),
      isMktgMsgAllowed: getValue(customerData, "isMktgMsgAllowed"),
    },
    paymentPreferences: {
      acceptCheck: getValue(customerData, "acceptCheck"),
      remotePaymentAllowed: getValue(customerData, "remotePaymentAllowed"),
      achAllowed: getValue(customerData, "achAllowed"),
    },
    agreementInfo: {
      activeAgreements: getValue(customerData, "activeAgreements"),
      inActiveAgreements: getValue(customerData, "inActiveAgreements"),
      pifAgreements: getValue(customerData, "pifAgreements"),
      epoAgreements: getValue(customerData, "epoAgreements"),
      coCustomer: getValue(customerData, "coCustomer"),
      coCustomerId: getValue(customerData, "coCustomerId"),
    },
    residenceInfo: {
      residenceSince: getValue(customerData, "residenceInfo.residenceSince"),
      residenceType: getValue(customerData, "residenceInfo.residenceType"),
    },
    legalInfo: {
      legalHold: getValue(customerData, "legalHold"),
      verified: getValue(customerData, "verified"),
      verifiedDate: getValue(customerData, "verifiedDate"),
    },
    customerRoutes: getArrayValue(customerData, "customerRoutes").map(
      (route: CustomerRoute) => ({
        customerRouteId: getValue(route, "customerRouteId"),
        storeRouteId: getValue(route, "storeRouteId"),
        storeNumber: getValue(route, "storeNumber"),
        routeCodeDesc: getValue(route, "routeCodeDesc"),
      })
    ),
    addresses: getArrayValue(customerData, "addresses").map(
      (address: Address) => ({
        addressId: getValue(address, "addressId"),
        addressType: getValue(address, "addressType"),
        addressTypeDesc: getValue(address, "addressTypeDesc"),
        addressLine1: getValue(address, "addressLine1"),
        addressLine2: getValue(address, "addressLine2"),
        city: getValue(address, "city"),
        state: getValue(address, "state"),
        postalCode: getValue(address, "postalCode"),
        latitude: getValue(address, "latitude"),
        longitude: getValue(address, "longitude"),
        active: getValue(address, "active"),
        verifiedDate: getValue(address, "verifiedDate"),
      })
    ),
    phones: getArrayValue(customerData, "phones").map((phone: Phone) => ({
      phoneId: getValue(phone, "phoneId"),
      phoneNumber: getValue(phone, "phoneNumber"),
      phoneType: getValue(phone, "phoneType"),
      phoneTypeDesc: getValue(phone, "phoneTypeDesc"),
      primary: getValue(phone, "primary"),
      callTimeType: getValue(phone, "callTimeType"),
      callTimeTypeDesc: getValue(phone, "callTimeTypeDesc"),
      active: getValue(phone, "active"),
      isNDNCOpted: getValue(phone, "isNDNCOpted"),
      isTrnsMsgAllowed: getValue(phone, "isTrnsMsgAllowed"),
      isMktgMsgAllowed: getValue(phone, "isMktgMsgAllowed"),
    })),
    consentInfo: getArrayValue(customerData, "communicationConsent").map(
      (consent: CommunicationConsent) => ({
        phoneNumber: getValue(consent, "phoneNumber"),
        consentType: getValue(consent, "consentType"),
        consent: getValue(consent, "consent"),
        lineOfBusiness: getValue(consent, "lineOfBusiness"),
        consentDate: getValue(consent, "consentDate"),
      })
    ),
    customerStatus: {
      customerSinceDate: getValue(customerData, "customerSinceDate"),
      createdBy: getValue(customerData, "createdBy"),
      lastModifiedBy: getValue(customerData, "lastModifiedBy"),
      createdDate: getValue(customerData, "createdDate"),
      lastModifiedDate: getValue(customerData, "lastModifiedDate"),
      createdSource: getValue(customerData, "createdSource"),
      lastModifiedSource: getValue(customerData, "lastModifiedSource"),
    },
  };
};

export const getIntellicheckDetails = async (
  approvalId: string,
  setIntellicheckVerfDetails: React.Dispatch<
    React.SetStateAction<IntellicheckCustomerDetails>
  >
) => {
  const getIntellicheckDetailsPayload: GetIntellicheckDetailsPayload = {
    customerApprovalId: `cust-${approvalId}`,
    businessUnit: BUSINESS_UNIT,
  };

  const getIntellicheckStatusResp: any =
    await getIntellicheckVerificationDetails(getIntellicheckDetailsPayload);
  // eslint-disable-next-line no-console
  console.log(
    "Response from getIntellicheckService",
    getIntellicheckStatusResp
  );
  if (getIntellicheckStatusResp?.status === 200) {
    const { data } = getIntellicheckStatusResp.data ?? {};

    if (data.status == "incomplete") {
      return "incomplete";
    } else {
      // eslint-disable-next-line no-console
      console.log("Response Data", data);
      // Destructure data with default values applied
      const {
        first_name = EMPTY_STRING,
        last_name = EMPTY_STRING,
        dob = EMPTY_STRING,
        address1 = EMPTY_STRING,
        address2 = EMPTY_STRING,
        locality = EMPTY_STRING,
        province_code = EMPTY_STRING,
        postal_code = EMPTY_STRING,
        country_code = EMPTY_STRING,
        name_score = 0,
        location_score = null,
        id_score = 0,
        id_document = {},
        matches = {},
        images = {},
      } = data ?? {};

      // Destructure nested properties with default values
      const {
        number = EMPTY_STRING,
        type = EMPTY_STRING,
        province_code: idDocProvinceCode = EMPTY_STRING,
      } = id_document;
      const {
        first_name: matchFirstName = false,
        last_name: matchLastName = false,
        dob: matchDob = false,
      } = matches;
      const { front = EMPTY_STRING, selfie = EMPTY_STRING } = images;

      const getIntellicheckDetails: IntellicheckCustomerDetails = {
        first_name,
        last_name,
        dob,
        address1,
        address2,
        locality,
        province_code,
        postal_code,
        country_code,
        name_score,
        location_score,
        id_score,
        id_document: {
          number,
          type,
          province_code: idDocProvinceCode,
        },
        matches: {
          first_name: matchFirstName,
          last_name: matchLastName,
          dob: matchDob,
        },
        images: {
          front,
          selfie,
        },
      };

      // eslint-disable-next-line no-console
      console.log("Intellicheck Details", getIntellicheckDetails);
      setIntellicheckVerfDetails(getIntellicheckDetails);
      uploadDocuments(approvalId, [
        {
          documentType: "IDENTTYDOC",
          file: front,
        },
        selfie && {
          documentType: "SELFIEIMG",
          file: selfie,
        },
      ]);
      return getIntellicheckDetails;
    }
  } else {
    setIntellicheckVerfDetails(INTELLICHECK_DETAILS_TEMPLATE);
    return INTELLICHECK_DETAILS_TEMPLATE;
  }
};

const getData = (key: string, iv: string, data: string) => {
  // eslint-disable-next-line no-console
  console.log("payloadKeys-->", key, iv, data);
  const decipher = createDecipheriv("aes-256-cbc", key, iv);
  let result: any = decipher.update(data, "hex", "utf8");
  result += decipher.final("utf8");
  // eslint-disable-next-line no-console
  console.log("payloadKeys", iv, key, data, result);
  return result;
};
