/* eslint-disable */
import { Grid, Typography, RACButton } from '@rentacenter/racstrap';


import doflogo from '../../../images/logo.svg';
import { useContext, useEffect, useState } from 'react';
import { FindCustomer } from '../../../api/user';
import { DigitalOrderFormContext } from '../Context/DigitialOrderFormContext';
import ScreenLoader from '../Common/ScreenLoader';
import { getCustomerDetails } from '../Common/GetCustomerDetails';
import { VerificationStyles } from '../../../styles/verificationStyles';
import { useHistory, useParams } from 'react-router-dom';


export const WelcomePage = () => {

  const { decisionEngineDetails, setCustomerDetails } = useContext(DigitalOrderFormContext)

  const [screenLoader, setScreenLoader] = useState(false);
  const classes: any = VerificationStyles();
  const queryParams = new URLSearchParams(window.location.search);
  const paramValue = queryParams.get("status");
  const { approvalId } = useParams<any>();
  const history = useHistory();

  useEffect(() => {
    pageLoad()
  }, [])

  const pageLoad = async () => {
    debugger
    if (decisionEngineDetails?.globalCustomerId) {
      const getCustomerDetailsRes: any = await FindCustomer({ GcId: decisionEngineDetails?.globalCustomerId }, window.sessionStorage.getItem("authKey") ? false : window.sessionStorage.getItem('storeNumber'),
        window.sessionStorage.getItem("authKey") ? window.sessionStorage.getItem("authKey") : window.sessionStorage.getItem('storeNumber'))
      if (getCustomerDetailsRes?.customerId) {
        await getCustomerDetails(getCustomerDetailsRes?.customerId, setCustomerDetails);
      }
    }
  }
  return (

    <Grid style={{width: '100%', height: '60vh'}}>
      <Grid style={{marginTop: '7%'}}>
      {screenLoader ? <ScreenLoader /> : null}
      <Grid style={{ textAlign: 'center', display: 'inline-flex' }}>
        <img
          src={doflogo}
          style={{
            float: 'left',
            verticalAlign: 'middle',
            padding: '16px 24px'
          }}
        />
      </Grid>



      <Typography className={`${classes.primaryFontColor} ${classes.font18px} ${classes.boldFont1} ${classes.p10px}`}>Welcome to Rent A Center</Typography>

      <Typography className={`${classes.font14px} ${classes.p10px}`}>Let's begin with the verification process</Typography>

      <RACButton
        color='primary'
        variant='contained'
        style={{ 
          marginTop: '20px'
        }}
        className={`${classes.primaryButtonColor} ${classes.buttonsPadding}`}
        onClick={() => {
          history.push(`/otp/auth/${approvalId}`)}
        }
      >
        Next
      </RACButton>
      </Grid>
    </Grid>)

};
